import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { RefObject } from "react";
import MomentUtils from '@date-io/moment';
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
  token?: string | null;
}
export interface PhoneNumberLengthDataItem {
  length: Array<number>;
  phone_code: string;
  flag: string;
  name: string;
}
export interface PhoneNumberLengthSuccessResponse {
  data: Array<PhoneNumberLengthDataItem>;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>;
}

export interface ApiFailureResponseError {
  message: string;
  email?: string;
  full_name?: string;
  user_name?: string;
}

export interface SelectDataType {
  id: string,
  type: string,
  attributes: {
    name: string,
    created_at: string,
    description?: string;
    manual?: boolean;
  }
}

export interface ICountryCode {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  termsAndCondition: boolean;
  selectedImage: string | null;
  selectedImageBase64: string | null;
  imgSizeExceed: boolean;
  phoneNumberLength: Array<PhoneNumberLengthDataItem>;
  phoneNumberData: Array<PhoneNumberLengthDataItem>;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  selectInterestData: { title: string; year: number }[];
  error: string;
  labelData: string;
  userName: string;
  dropdownListOpen: boolean;
  selectedCountryCode: PhoneNumberLengthDataItem | null;
  isCountryList: string;
  date: string;
  interestMenuRef: null | HTMLElement;
  countryCodeMenuRef: null | HTMLElement;
  fullNameError: string;
  phoneNumberError: string;
  options: Array<SelectDataType>;
  selectedItems: SelectDataType[];
  newItem: string;
  showModal: boolean;
  check: boolean;
  nameInputLabel: string;
  phoneInputLabel: string;
  emailInputLabel: string;
  userNameLabel: string;
  interestInputLabel: string;
  dateLabel: string;
  dropdownOpen: boolean;
  userNameError: string;
  dateRef: any;
  isSubmitting: boolean;
  termsAndConditionModal: boolean;
  termsAndConditionString: string;
  addInterestModalError: string;
  emailErrorMessage: string;
  isFormTouched: boolean;
  searchStringForCountry: string;
  isAddNewInterestInputOpen: boolean;
  manuallyAddedInterestList: SelectDataType[];
  token: string;
  isInvitedUser: boolean;
  isInterestModalUpdated: boolean;
  countryCode: ICountryCode;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  fileInputRef: RefObject<HTMLInputElement>;
  phoneNumberLengthApiCallId: string = "";
  countryCodeApiCallId: string = "";
  signUpApiCallId: string = "";
  getInterestApiCallId: string = "";
  addnewInterestApiCallId: string = "";
  termsAndConditionsDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      termsAndCondition: false,
      selectedImage: null,
      selectedImageBase64: null,
      imgSizeExceed: false,
      phoneNumberLength: [],
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      interestMenuRef: null,
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      error: "",
      selectInterestData: [],
      labelData: "",
      userName: "",
      phoneNumberData: [],
      dropdownListOpen: false,
      selectedCountryCode: {
        flag: "https://flagcdn.com/64x48/in.png",
        name: "India",
        phone_code: "+91",
        length: [10]
      },
      isCountryList: "+91",
      date: "",
      fullNameError: "",
      phoneNumberError: "",
      options: [],
      selectedItems: [],
      newItem: "",
      showModal: false,
      check: true,
      nameInputLabel: "",
      phoneInputLabel: "",
      emailInputLabel: "",
      userNameLabel: "",
      interestInputLabel: "",
      dateLabel: "",
      dropdownOpen: false,
      userNameError: "",
      dateRef: null,
      isSubmitting: false,
      termsAndConditionModal: false,
      termsAndConditionString: "",
      countryCodeMenuRef: null,
      addInterestModalError: "",
      emailErrorMessage: "",
      isFormTouched: false,
      searchStringForCountry: "",
      isAddNewInterestInputOpen: false,
      manuallyAddedInterestList: [],
      isInvitedUser: false,
      token: "",
      isInterestModalUpdated: true,
      countryCode: {
        countryCode: "in",
        dialCode: "91",
        format: "+.. .....-.....",
        name: "India",
      }
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ isSubmitting: false });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      helper.hideLoader();
     

      if (responseJson && !responseJson.errors) {
        this.apiResponseSucessCell(apiRequestCallId, responseJson);
      }
      else if (responseJson && responseJson.errors) {
        this.apiResponseFailureCall(apiRequestCallId, responseJson);
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await this.checkToken(false);
    this.getCountrycode();
    this.getInterest();
    this.getTermsAndConditionData();
    this.checkItIsInvitedUser();
  }

  checkToken = async (bool: boolean)=>{
    const token = await getStorageData('authToken');
    if(token || bool){
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
      navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigation);
    }
  }

  checkItIsInvitedUser = ()=>{
    const pathname = this.props.navigation.history.location.pathname;
    
    if(pathname === "/EmailAccountRegistrationBlock"){
      const search = this.props.navigation.history.location.search;

      let redirectURL = "/signup";
      if (search) {
        redirectURL += search;
      }

      this.props.navigation.history.push(redirectURL)
      return;
    }
    const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
    const email = searchParams.get("email");
    const token = searchParams.get("invitation_token");

    if(email && token){
      this.setState({
        email: email,
        token: token,
        isInvitedUser: true
      })
    }
  }

  apiResponseSucessCell = async (apiRequestCallId: string, responseJson: { data: Array<PhoneNumberLengthDataItem> } & { data: Array<SelectDataType> } & { data: SelectDataType } ) => {
    if (apiRequestCallId === this.countryCodeApiCallId) {
      this.phoneNumberLengthSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.signUpApiCallId) {
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
      navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigation);
      helper.showSuccessToast("Account has been registered successfully!")
    }

    if (apiRequestCallId === this.getInterestApiCallId) {
      this.setState({
        options: responseJson.data
      })
    }

    if (apiRequestCallId === this.addnewInterestApiCallId) {
      const modifiedResponseData = responseJson.data;
      modifiedResponseData.attributes.manual = true;
      this.setState((prevState)=>{
        return { 
          showModal: false, 
          manuallyAddedInterestList:[...prevState.manuallyAddedInterestList, modifiedResponseData], 
          newItem: "", 
          isAddNewInterestInputOpen: false 
        }
      })
      this.getInterest();
    }

    if (apiRequestCallId === this.termsAndConditionsDataApiCallId) {
      this.setState({
        termsAndConditionString: responseJson.data[0] && responseJson.data[0].attributes.description ? responseJson.data[0].attributes.description : ""
      })
    }
  };

  apiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.countryCodeApiCallId) {
      this.phoneNumberLengthFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.signUpApiCallId) {
      if (responseJson.errors[0].message && responseJson.errors[0].message === 'Phone number already exists') {
        this.setState({
          phoneNumberError: 'Phone number already exists'
        })
      } else if ((responseJson.errors[0].message && responseJson.errors[0].message === 'Username already exists') || (responseJson.errors[0].user_name && responseJson.errors[0].user_name === 'Username has already been taken')) {
        this.setState({
          userNameError: 'Username already exists'
        })
      } else if (responseJson.errors.length) {
        this.handleErrors(responseJson);
      }
    }
  };

  handleErrors = (responseJson: ApiFailureResponse) => {
    let isEmailError = false;
    let emailErrorMsg = "";
    responseJson.errors.forEach((error) => {
      if (error.email) {
        isEmailError = true;
        emailErrorMsg = error.email;
      }
      if (error.message) {
        helper.showErrorToast(error.message)
      }
    })
    if (isEmailError) {
      this.setState({
        emailErrorMessage: emailErrorMsg
      })
    }
  }

  phoneNumberLengthSucessCallBack = (response: { data: Array<PhoneNumberLengthDataItem> }) => {
    this.setState({ phoneNumberData: response.data });
  };

  phoneNumberLengthFailureCallBack = (response: ApiFailureResponse) => {
  };

  handleInterestMenuList = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      interestMenuRef: event.currentTarget,
      dropdownOpen: true,
      manuallyAddedInterestList: this.state.selectedItems,
      isInterestModalUpdated: false
    })
  }

  hanldeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Trim leading spaces only
    let sanitizedValue = value.replace(/^\s+/, ''); // Remove leading spaces using regex

    // Replace any invalid characters (only allow A-Z, a-z, and spaces)
    sanitizedValue = sanitizedValue.replace(/[^A-Za-z\s]/g, ''); // Remove invalid characters

    this.setState({
      firstName: sanitizedValue,
      fullNameError: ""
    })
  }

  handleUserNameChange = (value: string) => {
    const sanitizedValue = value.trim();
    this.setState({
      userName: sanitizedValue,
      userNameError: ""
    })
  }

  handleNumberChange = (value: string, country: ICountryCode) => {
    const sanitizedValue = value.replace(/\D/g, "");
    this.setState({
      phone: sanitizedValue,
      countryCode: country,
    })
    const error = helper.validatePhoneNumber(value, country, this.state.phoneNumberData)?.error;
    this.setState({ phoneNumberError: error })
  }

  toggleTermsAndConditionModal = () => {
    this.setState(prevState => {
      return {
        termsAndConditionModal: !prevState.termsAndConditionModal
      }
    })
  }

  handleDateChange = (value: any) => {
    const moment = new MomentUtils();
    const formattedDate = moment.date(value).format("DD MMMM YYYY")
    this.setState({ date: formattedDate })
  }

  handleEmailChange = (value: string) => {
    // Trim leading spaces only
    let sanitizedValue = value.trim(); // Remove leading spaces using regex

    this.setState({ email: sanitizedValue, emailErrorMessage: "" })
  }

  handleNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      newItem: event.target.value,
      addInterestModalError: ""
    });
  };

  getTermsAndConditionData = async () => {
    this.termsAndConditionsDataApiCallId = await this.apiCallFunction({
      contentType: configJSON.contentTypeApiAddDetail,
      method: configJSON.validationApiMethodType,
      endPoint: "bx_block_terms_and_conditions/terms_and_conditions",
    })
  }

  emailAccountRegistrationMain = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      token: "",
      "Content-Type": contentType,
    };
    let contractDetailsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(
      contractDetailsrequestMessage.id,
      contractDetailsrequestMessage
    );
    return contractDetailsrequestMessage.messageId;
  };

  getPhoneNumberDetails = async () => {
    this.phoneNumberLengthApiCallId = await this.emailAccountRegistrationMain({
      method: configJSON.contractListingMethod,
      endPoint: configJSON.contractListingEndPoint,
    });
  };

  getInterest = async () => {
    this.getInterestApiCallId = await this.apiCallFunction({
      contentType: configJSON.contentTypeApiAddDetail,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.interestApiEndPoint,
    })
  }

  onClickTerms = () => {
    this.setState({ termsAndCondition: !this.state.termsAndCondition });
  };

  onChooseFile = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.setState({ imgSizeExceed: false });
    }
  };

  getCountrycode = async () => {
    this.countryCodeApiCallId = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.phoneNumberApiEndPoint,
    });
  }

  apiCallFunction = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType, token } = data;
    const header = {
      token: token, 
      "Content-Type": contentType,
    };
    let apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type !== "formData"
      ? apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const validTypes = ['.png', '.jpg', '.jpeg'];
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        this.setState({ imgSizeExceed: true });
      }else if(!validTypes.find(format=> files[0].name.includes(format))){
        this.setState({ imgSizeExceed: true });
      } else {
        // Create a FileReader instance
        const reader = new FileReader();

        // Set up onload event
        reader.onload = (e: any) => {
          // e.target.result contains the Base64-encoded image data
          const base64Image = e.target.result;

          // Construct the data URL
          // Now you can use 'dataUrl' to display the image
          this.setState({ selectedImageBase64: base64Image, imgSizeExceed: false });
        };
        reader.onerror = (e: any) => {
          console.error('Error occurred while reading the file:', e.target.error);
        };

        // Read the image file as a data URL (this triggers the onload event)
        reader.readAsDataURL(files[0]);
        let imgUrl = URL.createObjectURL(files[0]);
        this.setState({ selectedImage: imgUrl, imgSizeExceed: false });
      }
    }
    event.target.value = "";
  };

  checkValidation = () => {
    let validation = true;
    this.setState({
      isFormTouched: true
    })
    if (this.isStringNullOrBlank(this.state.firstName)) {
      this.setState({ fullNameError: configJSON.NameMsg })
      validation = false;
    }
    if (this.isStringNullOrBlank(this.state.phone)) {
      this.setState({ phoneNumberError: configJSON.phoneMsg })
      validation = false;
    } else if(helper.validatePhoneNumber(this.state.phone, this.state.countryCode, this.state.phoneNumberData).error) {
      this.setState({ phoneNumberError: helper.validatePhoneNumber(this.state.phone, this.state.countryCode, this.state.phoneNumberData).error })
      validation = false;
    }
    if(this.state.email && !this.emailReg.test(this.state.email)){
      this.setState({ emailErrorMessage: "Enter a valid email address" })
      validation = false;
    }
    return validation;
  }

  handleSignUp = async () => {
    let isFiledValidate = this.checkValidation();
    if (isFiledValidate) {
      helper.showLoader();
      this.setState({ isSubmitting: true });
      let nameArr: Array<string> = this.state.firstName.trim().split(" ");
      nameArr = nameArr.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      const bodyData = {
        data: {
          type: "sms_account",
          attributes: {
            full_name: nameArr.join(" ").trim(),
            user_name: this.state.userName.trim(),
            full_phone_number: `+${this.state.phone}`,
            date_of_birth: this.state.date,
            email: this.state.email,
            terms_and_conditions: this.state.termsAndCondition.toString(),
            profile_image: this.state.selectedImageBase64,
            interests_attributes: this.state.selectedItems.map(item=> item.id),
            invitation_token: this.state.token
          }
        }
      }
      this.signUpApiCallId = await this.apiCallFunction({
        contentType: "application/json",
        method: configJSON.apiMethodTypeAddDetail,
        endPoint: configJSON.signUpApiEndPoint,
        body: bodyData
      })
    }
  }

  submitNewInterest = async () => {
    if(this.state.manuallyAddedInterestList.length >= 5){
      this.setState({
        addInterestModalError:"Can't select more than 5 interests"
      })
      return;
    }
    if(this.checkInterestAlreadyAdded(this.state.newItem)){
      this.setState({
        addInterestModalError:"Interest already selected"
      })
      return;
    }
    const exist = this.state.options.find(option=> option.attributes.name.toLowerCase() === this.state.newItem.toLowerCase());
    if(exist){
      this.setState((prevState)=>{
        return {
          manuallyAddedInterestList: [...prevState.manuallyAddedInterestList, exist],
          newItem:"",
          showModal: false,
          isAddNewInterestInputOpen: false
        }
      })
      return;
    }
    const bodyData = {
      name: this.state.newItem
    }

    this.addnewInterestApiCallId = await this.apiCallFunction({
      contentType: "application/json",
      method: configJSON.apiMethodTypeAddDetail,
      endPoint: configJSON.addNewInterestApiEndPoint,
      body: bodyData
    })
    this.setState({
      addInterestModalError: "",
      isInterestModalUpdated: true
    })
  }

  checkInterestAlreadyAdded = (interestId: string)=>{
    return this.state.manuallyAddedInterestList.find(item=> item.id === interestId || item.attributes.name.toLowerCase() === interestId.toLowerCase())
  }

  onAddInterest = ()=>{
    const interest = this.state.manuallyAddedInterestList;
    this.setState({
       selectedItems: interest,
       manuallyAddedInterestList: [],
       interestMenuRef: null
    })
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  handleLabelFocus = () => {
    this.setState({ labelData: configJSON.phoneMandate });
  };

  handleLabelBlur = (blueEventValue: React.FocusEvent<HTMLInputElement>) => {
    const { value } = blueEventValue.target;
    if (value === "") {
      this.setState({ labelData: "" });
    }
  };


  handleDelete = (interestId?: string) => {
    this.setState((prevState) => ({
      manuallyAddedInterestList: prevState.manuallyAddedInterestList.filter(
        (item) => item.id !== interestId
      ),
      isInterestModalUpdated: true
    }));
  };

  handleRemoveInterest = (interestId?: string) => {
    this.setState((prevState) => ({
      selectedItems: prevState.selectedItems.filter(
        (item) => item.id !== interestId
      ),
    }));
  };

  handleAddNewItemClick = () => {
    this.setState({
      isAddNewInterestInputOpen: true
    })
  };

  closeAddNewInterestInput = () => {
    this.setState({
      isAddNewInterestInputOpen: false,
      newItem: "",
      addInterestModalError: ""
    })
  };

  handleModalClose = () => {
    this.setState({ 
      showModal: false,
      newItem: "",
      addInterestModalError: ""
    });
  };

  navigateToLogin = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleNameFocus = () => {
    this.setState({ nameInputLabel: configJSON.nameMandateField });
  };

  handleNameBlur = (blurEvent: React.FocusEvent<HTMLInputElement>) => {
    const { value } = blurEvent.target;
    if (value === "") {
      this.setState({ nameInputLabel: "" });
    }
  };

  handleUserNameFocus = () => {
    this.setState({ userNameLabel: `${configJSON.userLabel}*` });
  };

  handleUserNameBlur = (blurEvent: React.FocusEvent<HTMLInputElement>) => {
    const { value } = blurEvent.target;
    if (value === "") {
      this.setState({ userNameLabel: "" });
    }
  };

  handleEmailFocus = () => {
    this.setState({ emailInputLabel: configJSON.emailMandateLabel });
  };

  handleEmailBlur = (blurEvent: React.FocusEvent<HTMLInputElement>) => {
    const { value } = blurEvent.target;
    if (value === "") {
      this.setState({ emailInputLabel: "" });
    }
  };


  onClickOptionItem = (interest: SelectDataType) => () => {
    const { options, manuallyAddedInterestList } = this.state;
    const clickedOption = options.find((option) => option.id === interest.id);
    
    if (clickedOption) {
      const isExist = manuallyAddedInterestList.find(item => item.id === clickedOption.id);
      if (!isExist && manuallyAddedInterestList.length < 5) {
        // if item already not exist and selectedItems length < 5 then add it
        const updatedSelectedItems = [...manuallyAddedInterestList, clickedOption]
        this.setState({ manuallyAddedInterestList: updatedSelectedItems, isInterestModalUpdated: true });
      }
    }
    
  };

  closeInterestModal = ()=>{
    this.setState({
      interestMenuRef: null,
      isAddNewInterestInputOpen: false,
      newItem: "",
      addInterestModalError: ""
    })
  }

  onSearchCountry = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    this.setState({
      searchStringForCountry: value
    })
  }

  onCloseCountryDropdown = ()=>{
    this.setState({ countryCodeMenuRef: null, dropdownListOpen: false, searchStringForCountry: "" });
  }


  // Customizable Area End
}
