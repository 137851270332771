import React from "react";
// Customizable Area Start
import {
  styled,
  Menu,
  IconButton,
  Tooltip
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Appointment as AppointmentType } from "./AppointmentsController";
import { MeetingType } from "./AddAppointmentController";
import Appointment from "./components/Appointment.web";
import ContactList from "./components/ContactList.web";
const { formatLocation} = require("./ApiCallFunction");
import AppointmentHeader from "./components/AppointmentHeader.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import { Carousel, InvitedPersonList } from "../../../components/src";
// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from "./AppointmentsController";
import { TimeSlot } from "./types";

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  RsvpStatus = (status: string | null) => {
    if (status === "accepted") {
      return (
        <StyledRsvpStatus className="Appointments_StyledRsvpStatus accepted">
          Accepted
        </StyledRsvpStatus>
      )
    }

    if (status === "rejected") {
      return (
        <StyledRsvpStatus className={`Appointments_StyledRsvpStatus ${status}`}>
          Rejected
        </StyledRsvpStatus>
      )
    }

    return (
      <StyledRsvpStatus className="Appointments_StyledRsvpStatus awaited">
        Awaited
      </StyledRsvpStatus>
    )
  }

  NoUpcomingAppointment = () => {
    return (
      <StyledNoUpcomingAppointment id="no-data-component" className="Appointments_StyledNoUpcomingAppointment">
        <div>
          <svg width="111" height="84" viewBox="0 0 111 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M93.9601 3.92666C93.8123 3.34713 94.3473 2.82497 94.9231 2.98677L98.8095 4.07891C99.3852 4.24071 99.5699 4.96511 99.1419 5.38284L96.2529 8.20249C95.8249 8.62022 95.1052 8.41797 94.9575 7.83845L93.9601 3.92666Z" fill="#FFBEBD" />
            <path d="M24.1686 3.66443C24.3983 2.71306 25.7515 2.71306 25.9811 3.66443L26.7541 6.86638C26.8361 7.20636 27.1016 7.4718 27.4416 7.55387L30.6435 8.32681C31.5949 8.55646 31.5949 9.90967 30.6435 10.1393L27.4416 10.9123C27.1016 10.9943 26.8361 11.2598 26.7541 11.5998L25.9811 14.8017C25.7515 15.7531 24.3983 15.7531 24.1686 14.8017L23.3957 11.5998C23.3136 11.2598 23.0482 10.9943 22.7082 10.9123L19.5062 10.1393C18.5549 9.90967 18.5549 8.55646 19.5062 8.32681L22.7082 7.55387C23.0482 7.4718 23.3136 7.20636 23.3957 6.86638L24.1686 3.66443Z" fill="#FFBEBD" />
            <path d="M4.52694 37.7196C4.64098 37.2472 5.31292 37.2473 5.42695 37.7197L5.81075 39.3096C5.8515 39.4784 5.98331 39.6102 6.15213 39.651L7.74206 40.0348C8.21446 40.1488 8.21446 40.8207 7.74205 40.9348L6.15213 41.3186C5.98331 41.3593 5.8515 41.4911 5.81075 41.6599L5.42695 43.2499C5.31291 43.7223 4.64098 43.7223 4.52694 43.2499L4.14314 41.6599C4.10239 41.4911 3.97058 41.3593 3.80177 41.3186L2.21184 40.9348C1.73944 40.8207 1.73944 40.1488 2.21184 40.0348L3.80177 39.651C3.97058 39.6102 4.10239 39.4784 4.14314 39.3096L4.52694 37.7196Z" fill="#FFBEBD" />
            <path d="M64.8947 12.2582C64.8947 13.4382 63.9382 14.3947 62.7582 14.3947C61.5783 14.3947 60.6217 13.4382 60.6217 12.2582C60.6217 11.0783 61.5783 10.1217 62.7582 10.1217C63.9382 10.1217 64.8947 11.0783 64.8947 12.2582Z" stroke="#FFBEBD" stroke-width="1.16536" />
            <path d="M101.831 71.059C102.06 70.1076 103.414 70.1076 103.643 71.059L104.416 74.2609C104.498 74.6009 104.764 74.8663 105.104 74.9484L108.306 75.7213C109.257 75.951 109.257 77.3042 108.306 77.5339L105.104 78.3068C104.764 78.3889 104.498 78.6543 104.416 78.9943L103.643 82.1962C103.414 83.1476 102.06 83.1476 101.831 82.1962L101.058 78.9943C100.976 78.6543 100.71 78.3889 100.37 78.3068L97.1683 77.5339C96.217 77.3042 96.217 75.951 97.1683 75.7213L100.37 74.9484C100.71 74.8663 100.976 74.6009 101.058 74.2609L101.831 71.059Z" fill="#FFBEBD" />
            <circle cx="90.9985" cy="44.9926" r="2.1365" stroke="#FC9999" stroke-width="1.16536" />
            <path d="M70.4768 22.2266H70.9881C76.8678 22.2266 81.6694 27.0116 81.6694 32.8465V72.7293C81.6694 78.5753 76.8567 83.3493 70.9881 83.3493H70.4768V37.3332C72.722 37.3332 74.5337 35.5208 74.5337 33.2996C74.5337 31.0673 72.7109 29.266 70.4768 29.266V22.2266ZM31.3971 22.2266H70.4768V29.255C68.2316 29.255 66.4088 31.0673 66.4088 33.2886C66.4088 35.5208 68.2316 37.3221 70.4768 37.3221V83.3493H31.3971V37.3332C33.6423 37.3332 35.4651 35.5208 35.4651 33.2996C35.4651 31.0673 33.6423 29.266 31.3971 29.266V22.2266ZM30.8747 22.2266H31.386V29.255C29.1408 29.255 27.3291 31.0673 27.3291 33.2886C27.3291 35.5208 29.1519 37.3221 31.386 37.3221V83.3493H30.8747C24.995 83.3493 20.1934 78.5642 20.1934 72.7293V32.8465C20.1934 27.0116 25.0061 22.2266 30.8747 22.2266Z" fill="white" />
            <path d="M70.4768 22.2266H70.9881C76.8678 22.2266 81.6694 27.0338 81.6694 32.8958V40.2454H70.4768V37.3921C72.722 37.3921 74.5337 35.5714 74.5337 33.3398C74.5337 31.0972 72.7109 29.2876 70.4768 29.2876V22.2266ZM31.3971 22.2266H70.4768V29.2876C68.2316 29.2876 66.4088 31.1083 66.4088 33.3398C66.4088 35.5825 68.2316 37.3921 70.4768 37.3921V40.2454H31.3971V37.3921C33.6423 37.3921 35.4651 35.5714 35.4651 33.3398C35.4651 31.0972 33.6423 29.2876 31.3971 29.2876V22.2266ZM30.8747 22.2266H31.386V29.2876C29.1408 29.2876 27.3291 31.1083 27.3291 33.3398C27.3291 35.5825 29.1519 37.3921 31.386 37.3921V40.2454H20.1934V32.8958C20.1934 27.0338 25.0061 22.2266 30.8747 22.2266Z" fill="url(#paint0_linear_18440_79257)" />
            <path d="M31.3167 19.0469C32.187 19.0469 32.9011 19.7607 32.9011 20.6308V31.5954C32.9011 32.4654 32.187 33.1793 31.3167 33.1793C30.4465 33.1793 29.7324 32.4654 29.7324 31.5954V20.6308C29.7324 19.7496 30.4353 19.0469 31.3167 19.0469ZM70.5454 19.0469C71.4156 19.0469 72.1297 19.7607 72.1297 20.6308V31.5954C72.1297 32.4654 71.4156 33.1793 70.5454 33.1793C69.6751 33.1793 68.9611 32.4654 68.9611 31.5954V20.6308C68.9722 19.7496 69.6751 19.0469 70.5454 19.0469Z" fill="white" />
            <path d="M43.7263 76.4847H38.234V55.753C36.2268 57.6307 33.8667 59.0223 31.1426 59.928V54.9357C32.5763 54.4607 34.1313 53.5771 35.8187 52.2627C37.4951 50.9484 38.6531 49.4241 39.2817 47.668H43.7373V76.4847H43.7263ZM56.6518 60.9994C55.2291 60.403 54.2035 59.5746 53.5528 58.5253C52.9131 57.476 52.5823 56.3273 52.5823 55.0682C52.5823 52.9254 53.3322 51.1582 54.8211 49.7665C56.31 48.3749 58.4385 47.668 61.1846 47.668C63.9087 47.668 66.0262 48.3638 67.5261 49.7665C69.026 51.1582 69.787 52.9365 69.787 55.0682C69.787 56.4047 69.4451 57.5865 68.7503 58.6247C68.0555 59.663 67.0849 60.4582 65.8387 60.9994C67.4268 61.64 68.64 62.5678 69.4671 63.7938C70.2943 65.0198 70.7134 66.4336 70.7134 68.0462C70.7134 70.697 69.8642 72.8508 68.1768 74.5076C66.4894 76.1644 64.2396 76.9928 61.4383 76.9928C58.8355 76.9928 56.6629 76.308 54.9314 74.9384C52.88 73.3147 51.8654 71.1057 51.8654 68.2782C51.8654 66.7208 52.2514 65.296 53.0234 64.0037C53.7844 62.7004 54.9975 61.7063 56.6518 60.9994ZM57.2805 67.748C57.2805 69.2612 57.6665 70.443 58.4385 71.2935C59.2105 72.1439 60.181 72.5637 61.339 72.5637C62.475 72.5637 63.4124 72.155 64.1513 71.3377C64.8902 70.5203 65.2652 69.3495 65.2652 67.8032C65.2652 66.4557 64.8902 65.3733 64.1293 64.567C63.3683 63.7497 62.4198 63.341 61.2508 63.341C59.9053 63.341 58.9127 63.8049 58.251 64.7327C57.6113 65.6605 57.2805 66.6656 57.2805 67.748ZM57.7878 55.4548C57.7878 56.5482 58.0966 57.4098 58.7142 58.0172C59.3318 58.6358 60.1589 58.934 61.1846 58.934C62.2323 58.934 63.0595 58.6247 63.6881 58.0062C64.3168 57.3877 64.6256 56.5262 64.6256 55.4327C64.6256 54.4055 64.3167 53.5771 63.6991 52.9586C63.0815 52.3401 62.2654 52.0308 61.2508 52.0308C60.192 52.0308 59.3539 52.3401 58.7252 52.9696C58.0966 53.5992 57.7878 54.4276 57.7878 55.4548Z" fill="#F75367" />
            <defs>
              <linearGradient id="paint0_linear_18440_79257" x1="50.9314" y1="22.2266" x2="50.9314" y2="40.2454" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F37F78" />
                <stop offset="1" stop-color="#F75367" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h1>No Upcoming Appointments</h1>
        <p>No scheduled Appointments. Stay tuned for the updates.</p>
      </StyledNoUpcomingAppointment>
    )
  }

  OptionMenu = (appointmentId: string, title: string) => {
    const { anchorOptionMenu } = this.state;
    return (
      <CustomMenu
        open={Boolean(anchorOptionMenu)}
        onClose={this.closeOptionMenu}
        anchorEl={anchorOptionMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <StyledOptionMenu id="options-menu" className="Appointments_StyledOptionMenu">
          <div onClick={()=>this.onEditAppointment(appointmentId)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.99997 16H3.2615L13.4981 5.7634L12.2366 4.50188L1.99997 14.7385V16ZM0.5 17.5V14.1154L13.6904 0.930775C13.8416 0.793426 14.0086 0.687292 14.1913 0.612375C14.374 0.537458 14.5656 0.5 14.7661 0.5C14.9666 0.5 15.1608 0.535584 15.3488 0.60675C15.5368 0.6779 15.7032 0.791034 15.848 0.94615L17.0692 2.18268C17.2243 2.32754 17.3349 2.49424 17.4009 2.68278C17.4669 2.87129 17.5 3.05981 17.5 3.24833C17.5 3.44941 17.4656 3.64131 17.3969 3.82403C17.3283 4.00676 17.219 4.17373 17.0692 4.32495L3.88458 17.5H0.5ZM12.8563 5.1437L12.2366 4.50188L13.4981 5.7634L12.8563 5.1437Z" fill="white" />
            </svg>
            Edit
          </div>
          <div onClick={() => this.onDeleteAppointment(title, appointmentId)}>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.3077 17.4998C2.80898 17.4998 2.38302 17.3232 2.02982 16.97C1.67661 16.6168 1.5 16.1908 1.5 15.6921V2.99981H0.5V1.49983H4.99997V0.615234H11V1.49983H15.5V2.99981H14.5V15.6921C14.5 16.1972 14.325 16.6248 13.975 16.9748C13.625 17.3248 13.1974 17.4998 12.6922 17.4998H3.3077ZM13 2.99981H2.99997V15.6921C2.99997 15.7818 3.02883 15.8556 3.08652 15.9133C3.14422 15.971 3.21795 15.9998 3.3077 15.9998H12.6922C12.7692 15.9998 12.8397 15.9678 12.9038 15.9037C12.9679 15.8395 13 15.769 13 15.6921V2.99981ZM5.40385 13.9998H6.90382V4.99981H5.40385V13.9998ZM9.09613 13.9998H10.5961V4.99981H9.09613V13.9998Z" fill="white" />
            </svg>
            Delete
          </div>
        </StyledOptionMenu>
      </CustomMenu>
    )
  }

  PagesMenu = ()=>{
    const { anchorPagesMenu } = this.state;
    return(
      <CustomMenu
        open={Boolean(anchorPagesMenu)}
        anchorEl={anchorPagesMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <StyledPagesMenu id="pages-menu" className="Appointments_StyledPagesMenu">
          <div className="page" onClick={()=>this.setPages(4)}>4</div>
          <div className="page" onClick={()=>this.setPages(8)}>8</div>
          <div className="page" onClick={()=>this.setPages(12)}>12</div>
        </StyledPagesMenu>
      </CustomMenu>
    )
  }

  AppointmentsComponent = () => {
    const { 
      currentPage, 
      itemsPerPage, 
      totalPages, 
      upcomingAppointmentList, 
      nextAppointment, 
      currentAnchorOptionMenu, 
      isRsvpStatusUpdateMode, 
      invitedUserList, 
      activeTab, 
      confirmationPopUpData, 
      userId
    } = this.state;
    return (
      <StyledAppointmentsContainer className="Appointments_StyledAppointmentContainer">
        <AppointmentHeader redirectTo={this.redirectTo} />
        {
          nextAppointment && nextAppointment.length > 0 && (
            <StyledOngoingAppointments className="Appointments_StyledOngoingAppointments">
              <header>Ongoing Appointment</header>
              <Carousel totalItems={nextAppointment.length}>
                {
                  nextAppointment.map(appointment => {
                    return (
                      <Appointment
                        appointment={appointment}
                        onUpdateRsvpStatus={this.onUpdateRsvpStatus}
                        setInvitedUsers={this.setInvitedUsers}
                        onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
                        activeTab={activeTab}
                        closeUserListModal={this.closeUserListModal}
                        setActiveTab={this.setActiveTab}
                        isRsvpStatusUpdateMode={isRsvpStatusUpdateMode}
                        invitedUserList={invitedUserList}
                        isListView={true}
                        redirectTo={this.redirectTo}
                        openDeleteModal={this.openDeleteModal}
                      />
                    )
                  })
                }
              </Carousel>
            </StyledOngoingAppointments>
          )
        }
        <StyledUpcomingAppointment className="Appointments_StyledUpcomingAppointment">
          {
            upcomingAppointmentList.length > 0 && (
              <>
                <header>Upcoming Appointments</header>
                <div className="table-container show-scrollbar">
                  <div className="table-headers">
                    <div className="header">Title</div>
                    <div className="header">Date and Time</div>
                    <div className="header">Location</div>
                    <div className="header">Type</div>
                    <div className="header">Invited Persons</div>
                    <div className="header">RSVP Status</div>
                  </div>
                  <div className="body-container">
                    {
                      upcomingAppointmentList.map((appointment: AppointmentType, appointmentIndex: number) => {
                        return (
                          <div className="appointment-container" key={appointmentIndex}>
                            <div className="data ellipsis capital-text" onClick={()=>this.goToAppointmentDetails(appointment.id)} data-test-id="appointment-title"><Tooltip title={appointment.attributes.title} placement="top"><span>{appointment.attributes.title}</span></Tooltip></div>
                            <div className="data ellipsis">{this.formateDate(appointment.attributes.appointment_date, "DD MMM, ddd")}{" "}{appointment.attributes.start_time}</div>
                            <div className="data ellipsis">{appointment.attributes.meeting_type === MeetingType.Offline ? (<Tooltip title={formatLocation(appointment.attributes.location)} placement="top-start"><span>{formatLocation(appointment.attributes.location)}</span></Tooltip>) : appointment.attributes.meeting_link}</div>
                            <div className="data ellipsis" style={{ textTransform: "capitalize" }}>{appointment.attributes.meeting_type}</div>
                            <div className="data">
                              <ContactList invited_users={appointment.attributes.invitation_members} list={appointment.attributes.user_contacts} owner_id={appointment.attributes.account_id} setInvitedUsers={()=>{
                                this.setInvitedUsers(appointment.attributes.user_contacts, appointment.attributes.account_id, appointment.attributes.invitation_members)
                              }} />
                            </div>
                            <div className="data rsvp">
                              {this.RsvpStatus(appointment.attributes.rsvp_status)}
                              {
                                userId && userId === appointment.attributes.account_id && (
                                  <div className="more-icon" style={{ cursor: "pointer" }} onClick={(event) => this.openOptionMenu(event, appointmentIndex)}>
                                    <IconButton>
                                      <svg width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.73042 3.49995C1.31794 3.49995 0.964819 3.35308 0.671069 3.05933C0.377335 2.76558 0.230469 2.41246 0.230469 1.99998C0.230469 1.58749 0.377335 1.23438 0.671069 0.940625C0.964819 0.646875 1.31794 0.5 1.73042 0.5C2.14292 0.5 2.49604 0.646875 2.78977 0.940625C3.08352 1.23438 3.23039 1.58749 3.23039 1.99998C3.23039 2.41246 3.08352 2.76558 2.78977 3.05933C2.49604 3.35308 2.14292 3.49995 1.73042 3.49995ZM7.49964 3.49995C7.08716 3.49995 6.73404 3.35308 6.44029 3.05933C6.14654 2.76558 5.99967 2.41246 5.99967 1.99998C5.99967 1.58749 6.14654 1.23438 6.44029 0.940625C6.73404 0.646875 7.08716 0.5 7.49964 0.5C7.91213 0.5 8.26524 0.646875 8.55899 0.940625C8.85274 1.23438 8.99962 1.58749 8.99962 1.99998C8.99962 2.41246 8.85274 2.76558 8.55899 3.05933C8.26524 3.35308 7.91213 3.49995 7.49964 3.49995ZM13.2689 3.49995C12.8564 3.49995 12.5033 3.35308 12.2095 3.05933C11.9158 2.76558 11.7689 2.41246 11.7689 1.99998C11.7689 1.58749 11.9158 1.23438 12.2095 0.940625C12.5033 0.646875 12.8564 0.5 13.2689 0.5C13.6814 0.5 14.0345 0.646875 14.3282 0.940625C14.622 1.23438 14.7688 1.58749 14.7688 1.99998C14.7688 2.41246 14.622 2.76558 14.3282 3.05933C14.0345 3.35308 13.6814 3.49995 13.2689 3.49995Z" fill="white" />
                                      </svg>
                                    </IconButton>
                                  </div>
                                )
                              }
                              {appointmentIndex === currentAnchorOptionMenu && this.OptionMenu(appointment.id, appointment.attributes.title)}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <StyledPagination className="Appointments_StyledPagination">
                  <div className="items-per-page-container">
                    Show
                    <div className="dropdown-container" onClick={this.openPagesMenu}>
                      {itemsPerPage}
                      <div className="icon">
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.99953 7.0377L0.345703 1.3839L1.39953 0.330078L5.99953 4.93008L10.5995 0.330078L11.6534 1.3839L5.99953 7.0377Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                    {this.PagesMenu()}
                  </div>
                  <div className="paginator">
                    <div data-test-id="prev-page" className={`icon ${currentPage === 1 ? "disabled" : ""}`} style={{ transform: "rotate(180deg)" }} onClick={this.prevPage}>
                      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 13.25L7.75 7L1.5 0.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <div className="pages">
                      {
                        this.calculatePages().map((page: number) => {
                          return (
                            <div data-test-id="page" onClick={() => this.setCurrentPage(page)} className={`page ${currentPage === page ? "active" : ""}`} key={page}>{page}</div>
                          )
                        })
                      }
                    </div>
                    <div data-test-id="next-page" className={`icon ${currentPage === totalPages ? "disabled" : ""}`} onClick={this.nextPage}>
                      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 13.25L7.75 7L1.5 0.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </StyledPagination>
              </>
            )
          }

          {
            upcomingAppointmentList.length === 0 && (
              this.NoUpcomingAppointment()
            )
          }
        </StyledUpcomingAppointment>
        <ConfirmationPopUp
          open={Boolean(confirmationPopUpData)}
          header={confirmationPopUpData?.header as string}
          message={confirmationPopUpData?.message as string}
          onAccept={this.onAcceptConfirmation}
          onReject={this.onCloseConfirmationModal}
          onClose={this.onCloseConfirmationModal}
        />
        {
          nextAppointment.length === 0 && invitedUserList && (
            <InvitedPersonList 
              userList={invitedUserList}
              closeUserListModal={this.closeUserListModal}
            />
          )
        }
      </StyledAppointmentsContainer>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.AppointmentsComponent()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledAppointmentsContainer = styled("div")({
  "&.Appointments_StyledAppointmentContainer": {
    display: "flex",
    flexDirection: "column",
    padding: "2rem 1rem 1rem",
    gap: "2.25rem",
    "& .ellipsis": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    }
  }
})

const StyledUpcomingAppointment = styled("div")({
  "&.Appointments_StyledUpcomingAppointment": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& header": {
      fontSize: "1rem",
      fontWeight: 500,
      color: "white"
    },
    "& .table-container": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      overflow: "auto",
      paddingBottom: "1rem",
      "& .table-headers": {
        display: "flex",
        padding: "0rem 1rem",
        minWidth: "50rem",
        boxSizing: "border-box",
        height: "3.75rem",
        alignItems: "center",
        "& .header": {
          fontSize: "1rem",
          fontWeight: 500,
          color: "white",
          width: "16.6666%",
          paddingRight: "1rem",
          boxSizing: "border-box",
          "&:last-child": {
            paddingRight: "0"
          },
          "&:nth-child(4)": {
            width: "10.6666%"
          },
          "&:nth-child(5)": {
            width: "22.6666%",
          },
        }
      },
      "& .body-container": {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        minWidth: "50rem",
        boxSizing: "border-box",
        "& .appointment-container": {
          display: "flex",
          height: "2.5rem",
          alignItems: "center",
          padding: "1rem",
          background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
          border: "1px solid #19B5B945",
          borderRadius: "3.0625rem",
          "& .data": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "#FFFFFFCC",
            width: "16.6666%",
            paddingRight: "1rem",
            boxSizing: "border-box",
            minWidth: 0,
            "&.rsvp": {
              display: "flex",
              gap: "2rem",
              alignItems: "center"
            },
            "&:last-child": {
              paddingRight: "0"
            },
            "&:nth-child(4)": {
              width: "10.6666%"
            },
            "&:nth-child(5)": {
              width: "22.6666%",
            },
            [`@media screen and (max-width: 1200px)`]: {
              "&.rsvp": {
                gap: "0.5rem"
              }
            },
          },
          "& .data:first-child": {
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline"
            }
          }
        }
      }
    }
  }
})

const StyledPagination = styled("div")({
  "&.Appointments_StyledPagination": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0rem 2rem",
    "& .items-per-page-container": {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "white",
      "& .dropdown-container": {
        width: "4.8125rem",
        height: "2.75rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(28, 150, 154, 0.2)",
        boxShadow: "0px 0px 10px 0px #0000000F",
        borderRadius: "6.25rem",
        fontSize: "1rem",
        cursor: "pointer"
      }
    },
    "& .paginator": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      "& .pages": {
        display: "flex",
        gap: "0.4rem",
        alignItems: "center",
        "& .page": {
          fontSize: "1rem",
          fontWeight: 400,
          color: "white",
          width: "2rem",
          height: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
          borderRadius: "100%",
          "&.active": {
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
          }
        }
      },
      "& .icon": {
        cursor: "pointer",
        "&.disabled": {
          opacity: 0.5,
          cursor: "auto"
        }
      }
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "0rem 1rem"
    },
    [`@media screen and (max-width: 500px)`]: {
      padding: 0,
      justifyContent: "center",
      "& .items-per-page-container": {
        display: "none"
      },
    },
  }
})

const StyledRsvpStatus = styled("div")({
  "&.Appointments_StyledRsvpStatus": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "5.875rem",
    height: "1.8125rem",
    borderRadius: "62.5rem",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: 500,
    "&.accepted": {
      border: "1px solid #6CD86C",
      color: "#6CD86C"
    },
    "&.rejected": {
      border: "1px solid #E46060",
      color: "#E46060"
    },
    "&.awaited": {
      border: "1px solid #E3E65D",
      color: "#E3E65D"
    }
  }
})

const StyledNoUpcomingAppointment = styled("div")({
  "&.Appointments_StyledNoUpcomingAppointment": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    "& h1": {
      color: "#ffffff",
      fontSize: "1.5rem",
      fontWeight: 500,
      margin: "1rem 0rem 0.3rem",
      textAlign: "center"
    },
    "& p": {
      color: "#FFFFFFCC",
      fontSize: "1rem",
      fontWeight: 500,
      margin: 0,
      textAlign: "center"
    }
  }
})

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0,
  },
  "& .MuiPaper-root": {
    background: "transparent"
  }
})

const StyledOptionMenu = styled("div")({
  "&.Appointments_StyledOptionMenu": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid #19B5B945",
    display: "flex",
    flexDirection: "column",
    width: "8.8125rem",
    height: "4.75rem",
    gap: "0.5rem",
    justifyContent: "center",
    padding: "0rem 1rem",
    borderRadius: "0.5rem",
    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      color: "white",
      fontSize: "1rem",
      fontWeight: 400,
      cursor: "pointer"
    }
  }
})

const StyledPagesMenu = styled("div")({
  "&.Appointments_StyledPagesMenu": {
    width: "4.8125rem",
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid #19B5B945",
    borderRadius: "0.5rem",
    padding: "0.2rem 0rem",
    "& > div": {
      padding: "0.3rem 1rem",
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: 400,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#0000001A"
      }
    }
  }
})

const StyledOngoingAppointments = styled("div")({
  "&.Appointments_StyledOngoingAppointments":{
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& > header":{
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(255, 255, 255, 1)"
    }
  }
})

// Customizable Area End
