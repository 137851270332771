// Customizable Area Start
import React from "react";
import RoutineController, {
    Props,
    ITaskItem
} from "./RoutineController";
import { styled } from "@material-ui/styles";
import { Button, DatePicker, WeekDayComponent, ConfirmationPopUp } from "../../../components/src";
import { PlusIcon } from "../../appointmentmanagement/src/assets";
import RoutineCard from "./components/RoutineCard.web";
// Customizable Area End

export default class Routine extends RoutineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    RoutineList = () => {
        const {
          tasks
        } = this.state;
        return (
            <StyledRoutineListContainer className="Routine_StyledRoutineListContainer">
                {
                    tasks.map(task => {
                        return (
                            <div className="routine" key={task.id}>
                                <header>{task.formattedDate}</header>
                                <div className="routine-cards">
                                    {
                                        task.items.map((card: ITaskItem, cardIndex: number) => {
                                            return (
                                                <RoutineCard 
                                                  routine={card} 
                                                  key={cardIndex} 
                                                  onDelete={this.openDeleteModal}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </StyledRoutineListContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            isDatePickerOpen,
            currentDate,
            confirmationPopUpData,
            isRoutineLoadng,
            tasks
        } = this.state;
        return (
            <StyledRoutineContainer className="Routine_StyledRoutineContainer">
                <div className="header">
                    <div className="left">
                        <div id="date-picker">
                            <DatePicker
                                open={isDatePickerOpen}
                                selectedDate={currentDate}
                                onOpen={this.openDatePicker}
                                onClose={this.closeDatePicker}
                                onChange={this.goToSelectedDate}
                            />
                        </div>
                    </div>
                    <div className="right">
                        <Button
                            text="Add Routine"
                            icon={PlusIcon}
                            onClick={()=> this.redirectTo("AddRoutine")}
                        />
                    </div>
                </div>
                <div className="weekdays">
                    <WeekDayComponent 
                      selectedDate={currentDate}
                      onChangeDate={this.goToSelectedDate}
                    />
                </div>
                {isRoutineLoadng && tasks.length === 0 && 
                <>
                    {[...Array(4)].map((_, index) => (
                        <div className="routine" key={index}>
                            <div className="routine-cards">
                                    <div key={index} className="shimmer shimmer-card">
                                    </div>
                            </div>
                        </div>
                    ))}
                </>
                }
               
                {this.RoutineList()}
             
                {
                    confirmationPopUpData && (
                        <ConfirmationPopUp
                            open={Boolean(confirmationPopUpData)}
                            header={confirmationPopUpData.header}
                            message={confirmationPopUpData.message}
                            onAccept={this.onAcceptConfirmation}
                            onReject={this.onCloseConfirmationModal}
                            onClose={this.onCloseConfirmationModal}
                        />
                    )
                }
            </StyledRoutineContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledRoutineContainer = styled("div")({
    "&.Routine_StyledRoutineContainer": {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        gap: "1rem",
        "& > .header": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        "& .react-datepicker__navigation--previous": {
            left: "15%",
            top: "2.8%"
        },
        "& .react-datepicker__current-month": {
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: "500",
            marginBottom: "0.5rem"
        },
        "& .react-datepicker__day-name": {
            color: "#ffffff",
            fontSize: "0.875rem",
            fontWeight: "500"
        },
        "& .react-datepicker__triangle": {
            display: "none"
        },
        "& .react-datepicker__header": {
            // background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            background: "transparent",
            border: "none",
            paddingBottom: 0,
            paddingTop: "1rem"
        },
        "& .react-datepicker": {
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            border: "1.21px solid #19B5B945",
            borderRadius: "1rem",
            overflow: "hidden"
        },
        "& .react-datepicker__navigation--next": {
            right: "15%",
            top: "2.8%"
        },
        "& .react-datepicker__day": {
            "&:hover": {
                background: "transparent",
                borderRadius: "100%"
            }
        },
        "& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected": {
            background: "transparent",
            // borderRadius: "100%"
        },
        "& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name": {
            margin: "0.5rem"
        },
        "& .react-datepicker-popper": {
            zIndex: 2,
            marginLeft: "-6.25rem"
        },
        '& .react-datepicker-popper[data-placement^="bottom"]': {
            padding: "0.5rem"
        },
    }
})

const StyledRoutineListContainer = styled("div")({
    "&.Routine_StyledRoutineListContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflow: "auto",
        // height: "calc(100% - 4rem)",
        boxSizing: "border-box",
        "& > .routine": {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            "& > header":{
               color: "rgba(255, 255, 255, 0.8)",
               fontSize: "0.875rem",
               fontWeight: 400
            },
            "& > .routine-cards": {
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem"
            }
        }
    }
})
// Customizable Area End
